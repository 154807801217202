<template>
  <div class="card">
    <div class="card-header">{{ $t('cert.certCreating') }}</div>
    <div class="card-body">
      <Form @submit="handleFormSend" :validation-schema="formValidationSchema" ref="fileUploadForm"
            autocomplete="off">
        <div class="fw-bold mb-3"><p>{{ $t('cert.forCreatingCert') }}</p></div>
        <div class="row mb-3">
          <div class="col">
            <label for="name" class="form-label">{{ $t('cert.certNameText') }}</label>
            <Field
                class="form-control"
                v-model="name"
                type="text"
                id="name"
                name="name"
                placeholder="Název certifikátu"
                label="Název certifikátu"
            />
            <ErrorMessage class="invalid-feedback" name="name"/>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col">
            <Field name="file" v-slot="{ handleChange, handleBlur }">
              <input
                  type="file"
                  name="file"
                  id="file"
                  class="form-control"
                  placeholder="Soubory k nahrání"
                  @change="handleChange, onFileChanged($event)"
                  @blur="handleBlur"
                  ref="files"
              /></Field>
            <ErrorMessage class="invalid-feedback" name="file"/>
          </div>
        </div>
        <div class="row mb-3">
          <p class="text-danger">Po vytvoření certifikátu již není možné soubor měnit.</p>
        </div>
        <div class="text-end">
          <button type="submit" class="btn btn-primary"><span v-if="uploading" class="spinner-border spinner-border-sm me-3" role="status">
                      <span class="sr-only">Loading...</span>
                    </span>{{ uploading ? $t('cert.processing') : $t('cert.certCreate') }}
          </button>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import http from '@/http';

export default {
  mounted() {
  },

  data() {
    return {
      name: null,
      file: null,
      formValidationSchema: {
        name: 'required',
        /*  file: 'required', */
      },
      uploading: false
    };
  },

  methods: {
    uploadFile() {
      let body = {
        name: this.name,
        file: this.file,
      };
      let self = this;
      self.uploading = true;
      http
          .post('/certs/create', body, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            self.emitter.emit('certAdded', response.data.cert);
            self.uploading = false;
            self.$router.push({ name: 'CertDetail', params: { uuid: response.data.cert.uuid } });
          });
    },

    onFileChanged(event) {
      this.file = this.$refs.files.files[0];
    },

    handleFormSend() {
      this.uploadFile();
      this.$refs.fileUploadForm.resetForm();
      document.getElementById('file').value = null;
    },
  },
};
</script>
